<template>
    <div class="bg-2 " id="top">
        <div class="nav-container nav-bg px-lg-0 px-3 pb-lg-0 pb-4">
            <lego-nav-bar drop-down-animation="bounce" class="container py-4" shadow="0" :items="menu">
                <template #logo>
                    <img src="../assets/img/logo.png" alt="#" class="logo-img mb-3 mt-3 mr-5">
                </template>
            </lego-nav-bar>
        </div>
        <div class="">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <footer-section></footer-section>
    </div>

</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import FooterSection from '@/views/components/FooterSection.vue';

export default {
    components: {
        LegoNavBar,
        FooterSection
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.bg-2 {
    font-family: poppins-light;
}
</style>
